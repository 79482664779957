@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fjalla+One&family=Orbitron&family=Roboto+Condensed:wght@300&family=Staatliches&family=VT323&display=swap');
body {
    font-family: 'Fjalla one', sans-serif;
    margin: 0;
    background: #13191b;
}
.terms_txt
{
    background: #00000023;
    font-size:20px;
    color:#ffffffe0;
    margin:50px 0;
    padding:20px;
}
.terms_txt p
{
    font-size:27px;
    color:#ffffff8a;
}
.promoBox {
    position: relative;
    z-index: -1;
    top: -250px;
}

.UI_Promo1 {
    background: url('/src/Pages/utils/img/UI_Promo1.png') no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
}

.container_1 {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
}

.containerLinear {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(#13191b4d, #13191b);
    z-index: -1;
}

.logo {
    background: url('/src/Pages/utils/img/GOFBrandLogo.png') no-repeat;
    background-size: contain;
    width:600px;
    height:250px;
    margin: 0 auto;
}

.intro_txt {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    font-size: 23px;
    color: #b9b9b9;
    text-align: center;
}

h1 {
    font-size: 50px;
    color: #b9b9b9;
    border-bottom: 1px solid #ef545446;
    width: 100%;
    max-width: 420px;
    margin: 30px auto;
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.faq_txt h2 {
    text-align: left;
    font-style: italic;
    font-size: 30px;
    color: #b9b9b969;
    padding: 10px;
}

.faq_txt p {
    font-size: 19px;
    color: #dddddd;
    line-height: 140%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 45px;
}

.container_faq {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
}

.container_faq h1 {
    margin: 0 auto 50px;
}

.dot {
    background: url('/src/Pages/utils/img/UI_Dot.png') no-repeat;
    background-size: contain;
    width: 21px;
    height: 21px;
}

.container_features {
    width: 100%;
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
}

.featuresBanner {
    background: url('/src/Pages/utils/img/UI_Features.png') no-repeat;
    position: absolute;
    margin: 0 auto;
    z-index: 1;
    width: 100%;
    max-width: 1920px;
    height: auto;
    top: -50px;
}

.features {
    font-size: 21px;
    color: #dddddd;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    padding:0 10px;

}

.features h2 {
    text-align: left;
    font-size: 30px;
    color: #b9b9b9b7;
}

.features div {
    margin: 0 0 50px 0;
}

.downloadbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 90px 0 0 0;
}

.downloadbox a {
    background: #ef5454a8;
    color: #ffffffb7;
    font-size: 25px;
    border: 1px solid #a13a3a;
    padding: 12px 0;
    border-radius: 5px;
    text-decoration: none;
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.downloadbox a:hover {
    background: #ef5454c2;
}

.steam-logo {
    background: url('/src/Pages/utils/img/logo-steam.svg') no-repeat;
    background-size: contain;
    width:150px;
    height:50px;
    opacity: .3;
    margin: 20px 0;
}

.teaserBox {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.footer {
    width: 100%;
    height: 350px;
    top: 0;
    border-top: 1px solid #ef545446;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff85;
    background: linear-gradient(#00000021, #00000021 50%);
}

.socials {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.twitter {
    background: url('/src/Pages/utils/img/Twitter.png') no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    opacity: .3;
    margin: 20px 0;
}

.discord {
    background: url('/src/Pages/utils/img/Discord.png') no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    opacity: .3;
    margin: 20px 0;
}

.slides {
    height: auto;
    position: relative;
}

.each-slide-effect {
    position: relative;
}

.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 100vh;
}

.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

.indicator {
    cursor: pointer;
    width: 50px;
    height: 5px;
    text-align: center;
    background: #ffffff49;
    margin: 0;
    margin: -50px 2px 0 2px;
    position: relative;
    z-index: 3;
}

.indicator.active {
    background: #ffffff96;
}

.each-slideshow-indicator {
    background: #fff!important;
    color: #fff!important;
}

.slide_txt {
    font-size: 80px;
    color: #ffffff8a;
    position: absolute;
    top: 250px;
    cursor: default;
    text-align: center;
}

.slide_txt_small {
    font-size: 35px;
    color: #ffffff8a;
    position: absolute;
    top: 400px;
    cursor: default;
    width: 100%;
    max-width: 600px;
    text-align: center;
    line-height: 150%;
}

.slideFadeLinear {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#13191b 5%, #13191b00);
    z-index: 0;
    pointer-events: none;
}
.terms_container
{
    width:60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0 auto;
}
.termsbox
{
display: flex;
flex-direction: row;
gap:10px;
font-size:15px;
margin:20px 0;
}
.termsbox a 
{
    color:#ffffff85;
    text-decoration: underline;
}
.termsbox a:hover
{
    text-decoration: none;
}
/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .logo {
        width: 280px;
        height:120px;
    }

    .intro_txt {
        font-size: 18px;
    }

    h1 {
        font-size: 35px;
        width: 80%;
    }

    .faq_txt h2 {
        font-size: 24px;
    }

    .faq_txt p {
        font-size: 16px;
        padding: 0 20px;
    }

    .features h2 {
        font-size: 24px;
    }

    .features {
        font-size: 18px;
        width: 100%;
    }

    .downloadbox a {
        font-size: 20px;
        width: 80%;
    }

    .steam-logo {
        width:150px;
        height:50px;
    }

    .slide_txt {
        font-size: 50px;
        top: 200px;
    }

    .slide_txt_small {
        font-size: 20px;
        top: 350px;
    }

    .slides, .each-slide-effect > div {
        width:100%;
        height: 500px;
    }
}

@media (max-width: 480px) {
    .intro_txt {
        font-size: 16px;
    }

    h1 {
        font-size: 28px;
    }

    .faq_txt h2 {
        font-size: 20px;
    }

    .faq_txt p {
        font-size: 14px;
    }

    .features h2 {
        font-size: 20px;
    }

    .features {
        font-size: 16px;
    }

    .downloadbox a {
        font-size: 18px;
    }

    .slide_txt {
        font-size: 40px;
        top: 150px;
    }

    .slide_txt_small {
        font-size: 18px;
        top: 300px;
    }
}
